"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _internals_1 = require("../_internals");
const symbols_1 = require("../_internals/symbols");
const mark = (0, symbols_1.setTypeclass)("Traversable");
const Traversable = (defs) => mark((_, globals) => {
    const overrides = (0, _internals_1.prop)("overrides")(defs);
    globals.traverse = function (arr, fn) {
        const res = [];
        const mark = Symbol("@@stop");
        const stop = this.pure({ [mark]: true });
        const pushOrReturn = (x) => {
            if (x[mark]) {
                return res;
            }
            res.push(x);
            return pushOrReturn;
        };
        const init = this.pure(pushOrReturn);
        return arr
            .map(fn)
            .concat(stop)
            .reduce((acc, next) => acc.applyLeft(next), init);
    };
    const traverseOverride = (0, _internals_1.prop)("traverse")(overrides);
    if (traverseOverride) {
        globals.traverse = traverseOverride;
    }
});
mark(Traversable);
exports.default = Traversable;
