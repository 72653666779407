"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCaseHasInstance = exports.setRepHasInstance = exports.setTypeclasses = exports.setTypeclass = exports.setVariant = exports.setTypeName = exports.setTypeRep = exports.setInnerValue = exports.setSymbol = exports.getTypeclasses = exports.getTypeclass = exports.getVariant = exports.getTypeName = exports.getTypeRep = exports.getInnerValue = exports.getSymbol = exports.Typeclasses = exports.Typeclass = exports.Variant = exports.TypeName = exports.TypeRep = exports.InnerValue = void 0;
exports.InnerValue = Symbol("@@value");
exports.TypeRep = Symbol("@@type");
exports.TypeName = Symbol("@@typename");
exports.Variant = Symbol("@@variant");
exports.Typeclass = Symbol("@@typeclass");
exports.Typeclasses = Symbol("@@typeclasses");
const getSymbol = (sym) => (withSym) => withSym[sym];
exports.getSymbol = getSymbol;
exports.getInnerValue = (0, exports.getSymbol)(exports.InnerValue);
exports.getTypeRep = (0, exports.getSymbol)(exports.TypeRep);
exports.getTypeName = (0, exports.getSymbol)(exports.TypeName);
exports.getVariant = (0, exports.getSymbol)(exports.Variant);
exports.getTypeclass = (0, exports.getSymbol)(exports.Typeclass);
exports.getTypeclasses = (0, exports.getSymbol)(exports.Typeclasses);
const setSymbol = (sym) => (value) => (withSym) => {
    withSym[sym] = value;
    return withSym;
};
exports.setSymbol = setSymbol;
exports.setInnerValue = (0, exports.setSymbol)(exports.InnerValue);
const setTypeRep = (t) => (val) => {
    Object.defineProperty(val, exports.TypeRep, {
        get: () => t()
    });
};
exports.setTypeRep = setTypeRep;
exports.setTypeName = (0, exports.setSymbol)(exports.TypeName);
exports.setVariant = (0, exports.setSymbol)(exports.Variant);
exports.setTypeclass = (0, exports.setSymbol)(exports.Typeclass);
exports.setTypeclasses = (0, exports.setSymbol)(exports.Typeclasses);
const setRepHasInstance = (rep) => {
    Object.defineProperty(rep, Symbol.hasInstance, {
        value: (instance) => {
            return (0, exports.getTypeRep)(instance) === rep;
        }
    });
};
exports.setRepHasInstance = setRepHasInstance;
const setCaseHasInstance = (Case) => {
    Object.defineProperty(Case, Symbol.hasInstance, {
        value: (instance) => {
            return (0, exports.getVariant)(instance) === (0, exports.getVariant)(Case)
                && (0, exports.getTypeRep)(instance) === (0, exports.getTypeRep)(Case);
        }
    });
};
exports.setCaseHasInstance = setCaseHasInstance;
