"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _internals_1 = require("../_internals");
const symbols_1 = require("../_internals/symbols");
/**
 * Adds show and toString method to proto
 */
const Show = (defs) => (0, symbols_1.setTypeclass)("Show")((cases) => {
    const overrides = (0, _internals_1.propOr)({}, "overrides", defs !== null && defs !== void 0 ? defs : {});
    Object.keys(cases).forEach(trivial => {
        var _a;
        function trivialShow() {
            return `[${(0, symbols_1.getTypeName)(this)} => ${(0, symbols_1.getVariant)(this)} ${(0, symbols_1.getInnerValue)(this)}]`;
        }
        const show = ((_a = overrides === null || overrides === void 0 ? void 0 : overrides.show) === null || _a === void 0 ? void 0 : _a[trivial]) || trivialShow;
        cases[trivial].prototype.show = show;
        cases[trivial].prototype.toString = show;
    });
});
(0, symbols_1.setTypeclass)("Show")(Show);
exports.default = Show;
