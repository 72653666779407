"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeWrapper = exports.getIgnore = exports.getCritical = exports.getFailure = exports.getSuccess = exports.getEnv = exports.setIgnore = exports.setCritical = exports.setFailure = exports.setSuccess = exports.setEnv = void 0;
const symbols_1 = require("../_internals/symbols");
const E = Symbol("@@env");
const S = Symbol("@@success");
const F = Symbol("@@failure");
const C = Symbol("@@critical");
const I = Symbol("@@ignored");
exports.setEnv = (0, symbols_1.setSymbol)(E);
exports.setSuccess = (0, symbols_1.setSymbol)(S);
exports.setFailure = (0, symbols_1.setSymbol)(F);
exports.setCritical = (0, symbols_1.setSymbol)(C);
exports.setIgnore = (0, symbols_1.setSymbol)(I);
exports.getEnv = (0, symbols_1.getSymbol)(E);
exports.getSuccess = (0, symbols_1.getSymbol)(S);
exports.getFailure = (0, symbols_1.getSymbol)(F);
exports.getCritical = (0, symbols_1.getSymbol)(C);
exports.getIgnore = (0, symbols_1.getSymbol)(I);
const makeWrapper = (success, failure) => {
    const env = (0, exports.setEnv)((x) => x);
    const s = (0, exports.setSuccess)(success);
    const f = (0, exports.setFailure)(failure);
    const e = (0, exports.setCritical)(undefined);
    const i = (0, exports.setIgnore)(false);
    return s(f(e(i(env({})))));
};
exports.makeWrapper = makeWrapper;
