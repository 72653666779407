"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _internals_1 = require("../_internals");
const symbols_1 = require("../_internals/symbols");
const mark = (0, symbols_1.setTypeclass)("Runnable");
const Runnable = (defs) => mark((cases) => {
    const overrides = (0, _internals_1.propOr)({}, "overrides", defs);
    Object.keys(cases).forEach(key => {
        function run() { return this.get(); }
        cases[key].prototype.run = run;
        cases[key].prototype.unsafeRun = run;
    });
    (0, _internals_1.defineOverrides)("run", ["unsafeRun"], overrides, cases);
});
mark(Runnable);
exports.default = Runnable;
