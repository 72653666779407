"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _internals_1 = require("../_internals");
const symbols_1 = require("../_internals/symbols");
const mark = (0, symbols_1.setTypeclass)("Enum");
/**
 * Adds succ and pred method to proto and pred, succ, fromEnum, toEnum and range to global
 */
const Enum = (defs) => mark((cases, globals) => {
    const order = (0, _internals_1.prop)("order")(defs);
    const defaultOrder = Object.keys(cases);
    const overrides = (0, _internals_1.propOr)({}, "overrides", defs);
    const toEnum = (0, _internals_1.prop)("toEnum")(overrides);
    const fromEnum = (0, _internals_1.prop)("fromEnum")(overrides);
    if (order) {
        globals.fromEnum = (en) => order.findIndex(x => x === (0, symbols_1.getVariant)(en));
        globals.toEnum = (i) => {
            const c = cases[order[i]];
            return c ? new c() : undefined;
        };
    }
    else if (toEnum && fromEnum) {
        globals.toEnum = toEnum;
        globals.fromEnum = fromEnum;
    }
    else {
        globals.fromEnum = (en) => defaultOrder.findIndex(x => x === (0, symbols_1.getVariant)(en));
        globals.toEnum = (i) => {
            const c = cases[defaultOrder[i]];
            return c ? new c() : undefined;
        };
    }
    (0, _internals_1.forEachValue)((variant) => {
        variant.prototype.succ = function () {
            return globals.toEnum(globals.fromEnum(this) + 1);
        };
        variant.prototype.pred = function () {
            return globals.toEnum(globals.fromEnum(this) - 1);
        };
    }, cases);
    globals.pred = (en) => en.pred();
    globals.succ = (en) => en.succ();
    globals.range = (start, end) => {
        var _a;
        const res = [];
        let cur = start;
        while (cur && !cur.equals(end)) {
            res.push(cur);
            cur = cur.succ();
        }
        if ((_a = cur === null || cur === void 0 ? void 0 : cur.equals) === null || _a === void 0 ? void 0 : _a.call(cur, end)) {
            res.push(end);
        }
        return res;
    };
});
mark(Enum);
exports.default = Enum;
