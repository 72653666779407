"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _internals_1 = require("../_internals");
const symbols_1 = require("../_internals/symbols");
const _tools_1 = require("../_tools");
const mark = (0, symbols_1.setTypeclass)("Natural");
/**
 * Adds a natural tranformation. Adds to method to proto and natural method to global
 */
const Natural = (defs) => mark((cases, globals) => {
    const natKey = (0, _internals_1.propOr)("of", "natural", defs);
    const overrides = (0, _internals_1.propOr)({}, "overrides", defs);
    globals.natural = function (...args) {
        return this[natKey](...args);
    };
    (0, _internals_1.forEachValue)((variant) => {
        function NatTrans(other) {
            if ((0, _tools_1.hasInstance)(Natural, other)) {
                const tcs = (0, symbols_1.getTypeclasses)(this)();
                const defaultKey = '__default';
                return (0, _internals_1.getFirstCaseSensitive)([...tcs, defaultKey], {
                    Functor: () => this.map(other.natural).get(),
                    Monad: () => this.flatMap(other.natural),
                    [defaultKey]: () => other.natural(this.get())
                })();
            }
            throw Error("Cannot transform into something that doesn't have a Natural transform");
        }
        variant.prototype.to = NatTrans;
        (0, _internals_1.defineOverrides)("to", [], overrides, cases);
    }, cases);
});
mark(Natural);
exports.default = Natural;
