"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _internals_1 = require("../_internals");
const symbols_1 = require("../_internals/symbols");
const mark = (0, symbols_1.setTypeclass)("BoxedEnum");
/**
 * Adds the next and previous methods to proto and global.
 * Requires Functor and Enum
 */
const BoxedEnum = () => mark((cases, globals) => {
    function next() {
        const val = this.get();
        const suc = this.succ();
        return (0, _internals_1.isNil)(suc) ? undefined : suc.mapTo(val);
    }
    function prev() {
        const val = this.get();
        const pre = this.pred();
        return (0, _internals_1.isNil)(pre) ? undefined : pre.mapTo(val);
    }
    function getTag() {
        return (0, symbols_1.getVariant)(this);
    }
    (0, _internals_1.forEachValue)((c) => {
        c.prototype.next = next;
        c.prototype.prev = prev;
        c.prototype.getTag = getTag;
        c.prototype.getVariant = getTag;
    }, cases);
    globals.next = (x) => x.next();
    globals.prev = (x) => x.prev();
});
mark(BoxedEnum);
exports.default = BoxedEnum;
