"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _internals_1 = require("../_internals");
const symbols_1 = require("../_internals/symbols");
/**
 * Adds mappend, append and empty method to proto and empty, accumulate and foldMap to globals
 */
const Monoid = (defs) => (0, symbols_1.setTypeclass)("Monoid")((cases, globals) => {
    const trivials = (0, _internals_1.propOr)([], "trivials", defs);
    const identities = (0, _internals_1.propOr)([], "identities", defs);
    const overrides = (0, _internals_1.propOr)({}, "overrides", defs);
    const zero = (0, _internals_1.prop)("zero")(defs);
    trivials.forEach(trivial => {
        function mappend(m) { return this.concat(m); }
        cases[trivial].prototype.mappend = mappend;
        cases[trivial].prototype.append = mappend;
    });
    identities.forEach(empt => {
        function mappend(m) { return m; }
        cases[empt].prototype.mappend = mappend;
        cases[empt].prototype.append = mappend;
    });
    Object.keys(cases).forEach(key => {
        function empty() {
            return globals.empty();
        }
        cases[key].prototype.empty = empty;
    });
    (0, _internals_1.defineOverrides)("mappend", ["append"], overrides, cases);
    (0, _internals_1.defineOverrides)("empty", [], overrides, cases);
    globals.empty = function () { return new cases[zero](); };
    globals.accumulate = function (arr) {
        return arr.reduce((acc, next) => acc.append(next), globals.empty());
    };
    globals.foldMap = function (arr) {
        const mapper = (a) => this.of(a);
        return arr
            .map(mapper)
            .reduce((acc, next) => acc.append(next), globals.empty());
    };
});
(0, symbols_1.setTypeclass)("Monoid")(Monoid);
exports.default = Monoid;
