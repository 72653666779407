"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _internals_1 = require("../_internals");
const symbols_1 = require("../_internals/symbols");
/**
 * Adds concat method to proto
 */
const Semigroup = (defs) => (0, symbols_1.setTypeclass)("Semigroup")((cases) => {
    const trivials = (0, _internals_1.propOr)([], "trivials", defs);
    const identities = (0, _internals_1.propOr)([], "identities", defs);
    const overrides = (0, _internals_1.propOr)({}, "overrides", defs);
    trivials.forEach(trivial => {
        function concat(m) {
            const mVar = (0, symbols_1.getVariant)(m);
            if (mVar !== trivial) {
                return this;
            }
            return new cases[trivial](this.get().concat(m.get()));
        }
        cases[trivial].prototype.concat = concat;
        cases[trivial].prototype.sconcat = concat;
    });
    identities.forEach(empt => {
        function concat(m) {
            return m;
        }
        cases[empt].prototype.concat = concat;
        cases[empt].prototype.sconcat = concat;
    });
    (0, _internals_1.forEachValue)((override, key) => {
        cases[key].prototype.concat = override;
        cases[key].prototype.sconcat = override;
    }, (overrides === null || overrides === void 0 ? void 0 : overrides.concat) || {});
});
(0, symbols_1.setTypeclass)("Semigroup")(Semigroup);
exports.default = Semigroup;
