"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FunctorError = void 0;
const _internals_1 = require("../_internals");
const symbols_1 = require("../_internals/symbols");
/**
 * Adds map, mapTo and fmap methods to proto
 */
const Functor = (defs = {}) => (0, symbols_1.setTypeclass)("Functor")((cases) => {
    const trivials = (0, _internals_1.propOr)([], "trivials", defs);
    const identities = (0, _internals_1.propOr)([], "identities", defs);
    const overrides = (0, _internals_1.propOr)({}, "overrides", defs);
    trivials.forEach(trivial => {
        function trivialFmap(fn) {
            return new cases[trivial](fn(this.get()));
        }
        cases[trivial].prototype.fmap = trivialFmap;
        cases[trivial].prototype.map = trivialFmap;
        cases[trivial].prototype.mapTo = function (b) {
            return this.map(() => b);
        };
    });
    identities.forEach(empt => {
        function idFmap() {
            return this;
        }
        cases[empt].prototype.fmap = idFmap;
        cases[empt].prototype.map = idFmap;
        cases[empt].prototype.mapTo = idFmap;
    });
    (0, _internals_1.defineOverrides)("fmap", ["map"], overrides, cases);
});
(0, symbols_1.setTypeclass)("Functor")(Functor);
/**
 * Adds mapError method to proto of error cases
 */
const FunctorError = (defs) => (0, symbols_1.setTypeclass)("FunctorError")((cases) => {
    const errors = (0, _internals_1.propOr)([], "errors", defs);
    const overrides = (0, _internals_1.propOr)({}, "overrides", defs);
    const [lefts, rights] = (0, _internals_1.splitBy)(c => !(0, _internals_1.includes)(c, errors), Object.keys(cases));
    lefts.forEach(left => {
        cases[left].prototype.mapError = function (fn) {
            return new cases[left](fn(this.get()));
        };
    });
    function idMapError() {
        return this;
    }
    rights.forEach(right => {
        cases[right].prototype.mapError = idMapError;
    });
    (0, _internals_1.forEachValue)((override, key) => {
        cases[key].prototype.mapError = override;
    }, overrides === null || overrides === void 0 ? void 0 : overrides.mapError);
});
exports.FunctorError = FunctorError;
(0, symbols_1.setTypeclass)("FunctorError")(exports.FunctorError);
exports.default = Functor;
