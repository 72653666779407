"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _internals_1 = require("../_internals");
const symbols_1 = require("../_internals/symbols");
const mark = (0, symbols_1.setTypeclass)("Filterable");
/**
 * Adds filter method to proto
 */
const Filterable = (defs) => mark((cases) => {
    const trivials = (0, _internals_1.propOr)([], "trivials", defs);
    const identities = (0, _internals_1.propOr)([], "identities", defs);
    const overrides = (0, _internals_1.propOr)({}, "overrides", defs);
    trivials.forEach(trivial => {
        function filter(fn) {
            return new cases[trivial](this.get().filter(fn));
        }
        cases[trivial].prototype.filter = filter;
    });
    identities.forEach(empt => {
        function filter() {
            return this;
        }
        cases[empt].prototype.filter = filter;
    });
    (0, _internals_1.defineOverrides)("filter", [], overrides, cases);
});
mark(Filterable);
exports.default = Filterable;
