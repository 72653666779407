"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _internals_1 = require("../_internals");
const symbols_1 = require("../_internals/symbols");
const mark = (0, symbols_1.setTypeclass)("Applicative");
/**
 * Adds apply, applyLeft, applyRight methods to proto
*/
const Applicative = (defs) => mark((cases, globals) => {
    const trivials = (0, _internals_1.propOr)([], "trivials", defs);
    const identities = (0, _internals_1.propOr)([], "identities", defs);
    const overrides = (0, _internals_1.propOr)({}, "overrides", defs);
    const pure = (0, _internals_1.prop)("pure")(defs);
    trivials.forEach((trivial) => {
        function ap(other) {
            var _a, _b;
            return (_b = (_a = other) === null || _a === void 0 ? void 0 : _a.match) === null || _b === void 0 ? void 0 : _b.call(_a, {
                [trivial]: (fn) => this.map(fn),
                _: () => other
            });
        }
        cases[trivial].prototype.apply = ap;
        cases[trivial].prototype.applyRight = ap;
        cases[trivial].prototype.applyLeft = function apLeft(ap) {
            return ap.apply(this);
        };
    });
    identities.forEach((empt) => {
        function id() { return this; }
        cases[empt].prototype.apply = id;
        cases[empt].prototype.applyRight = id;
        cases[empt].prototype.applyLeft = id;
    });
    (0, _internals_1.defineOverrides)("apply", ["applyRight"], overrides, cases);
    globals.pure = (...args) => new cases[pure](...args);
});
mark(Applicative);
exports.default = Applicative;
