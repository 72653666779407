"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _internals_1 = require("../_internals");
const symbols_1 = require("../_internals/symbols");
const mark = (0, symbols_1.setTypeclass)("Swap");
/**
 * Adds swap method to proto
 */
const Swap = (defs) => mark((cases) => {
    var _a, _b;
    const left = (0, _internals_1.prop)("left")(defs);
    const right = (0, _internals_1.prop)("right")(defs);
    const overrides = (0, _internals_1.propOr)({}, "overrides", defs);
    function triviallswap() {
        return new cases[right](this.get());
    }
    function trivialrswap() {
        return new cases[left](this.get());
    }
    function swapIf(fn) {
        const variant = (0, symbols_1.getVariant)(this);
        return fn(this.get()) ? this.swap() : new cases[variant](this.get());
    }
    const lswap = ((_a = overrides === null || overrides === void 0 ? void 0 : overrides.swap) === null || _a === void 0 ? void 0 : _a[left]) || triviallswap;
    const rswap = ((_b = overrides === null || overrides === void 0 ? void 0 : overrides.swap) === null || _b === void 0 ? void 0 : _b[right]) || trivialrswap;
    cases[left].prototype.swap = lswap;
    cases[right].prototype.swap = rswap;
    cases[left].prototype.swapIf = swapIf;
    cases[right].prototype.swapIf = swapIf;
    cases[left].prototype.swapOn = swapIf;
    cases[right].prototype.swapOn = swapIf;
});
mark(Swap);
exports.default = Swap;
