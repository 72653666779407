"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _internals_1 = require("../_internals");
const symbols_1 = require("../_internals/symbols");
const mark = (0, symbols_1.setTypeclass)("Tap");
/**
 * Adds effect and peak method to proto
 */
const Tap = (defs) => mark((cases) => {
    const trivials = (0, _internals_1.propOr)([], "trivials", defs);
    const identities = (0, _internals_1.propOr)([], "identities", defs);
    const overrides = (0, _internals_1.propOr)({}, "overrides", defs);
    trivials.forEach(trivial => {
        function trivialTap(fn) {
            return this.map(x => {
                fn(x);
                return x;
            });
        }
        cases[trivial].prototype.peak = trivialTap;
        cases[trivial].prototype.tap = trivialTap;
    });
    identities.forEach(empt => {
        function idEffect() {
            return this;
        }
        cases[empt].prototype.peak = idEffect;
        cases[empt].prototype.tap = idEffect;
    });
    (0, _internals_1.forEachValue)((variant) => {
        function baseImpl(pat) {
            this.match(pat);
            return this;
        }
        variant.prototype.matchEffect = baseImpl;
        variant.prototype.when = baseImpl;
    }, cases);
    (0, _internals_1.defineOverrides)("tap", ["peak", "effect"], overrides, cases);
});
mark(Tap);
exports.default = Tap;
