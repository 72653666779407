"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _internals_1 = require("../_internals");
const symbols_1 = require("../_internals/symbols");
const mark = (0, symbols_1.setTypeclass)("Eq");
/**
 * Adds equals method to proto and global
*/
const Eq = (defs) => mark((cases, globals) => {
    const trivials = (0, _internals_1.propOr)([], "trivials", defs);
    const empties = (0, _internals_1.propOr)([], "empties", defs);
    const overrides = (0, _internals_1.propOr)({}, "overrides", defs);
    function trivialEquals(other) {
        var _a, _b;
        if ((0, symbols_1.getTypeName)(this) !== (0, symbols_1.getTypeName)(other) ||
            (0, symbols_1.getVariant)(this) !== (0, symbols_1.getVariant)(other)) {
            return false;
        }
        const a = (_a = this === null || this === void 0 ? void 0 : this.get) === null || _a === void 0 ? void 0 : _a.call(this);
        const b = (_b = other === null || other === void 0 ? void 0 : other.get) === null || _b === void 0 ? void 0 : _b.call(other);
        if (a === null || a === void 0 ? void 0 : a.equals) {
            return a.equals(b);
        }
        if (b === null || b === void 0 ? void 0 : b.equals) {
            return b.equals(a);
        }
        return (0, _internals_1.equals)(a, b);
    }
    function emptyEquals(other) {
        return (0, symbols_1.getTypeName)(this) === (0, symbols_1.getTypeName)(other) && (0, symbols_1.getVariant)(this) === (0, symbols_1.getVariant)(other);
    }
    trivials.forEach(trivial => {
        cases[trivial].prototype.equals = trivialEquals;
    });
    empties.forEach(empt => {
        cases[empt].prototype.equals = emptyEquals;
    });
    (0, _internals_1.forEachValue)((override, key) => {
        cases[key].prototype.equals = override;
    }, (overrides === null || overrides === void 0 ? void 0 : overrides.equals) || {});
    globals.equals = _internals_1.equals;
});
mark(Eq);
exports.default = Eq;
