"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _internals_1 = require("../_internals");
const symbols_1 = require("../_internals/symbols");
const mark = (0, symbols_1.setTypeclass)("Thenable");
const thenableOf = (thenImpl) => {
    return {
        then: thenImpl,
        catch(fn = _internals_1.identity) {
            this.then(undefined, fn);
        }
    };
};
/**
 * Add toPromise and toThenable methods to proto
 */
const Thenable = (defs) => mark((cases) => {
    const resolvers = (0, _internals_1.propOr)([], "resolve", defs);
    const rejecters = (0, _internals_1.propOr)([], "reject", defs);
    const overrides = (0, _internals_1.propOr)({}, "overrides", defs);
    resolvers.forEach((variant) => {
        cases[variant].prototype.toPromise = function () {
            return Promise.resolve(this.get());
        };
        cases[variant].prototype.toThenable = function () {
            return thenableOf((resolve = _internals_1.identity) => {
                resolve(this.get());
            });
        };
    });
    rejecters.forEach((variant) => {
        cases[variant].prototype.toPromise = function () {
            return Promise.reject(this.get());
        };
        cases[variant].prototype.toThenable = function () {
            return thenableOf((__, reject = _internals_1.identity) => {
                reject(this.get());
            });
        };
    });
    (0, _internals_1.defineOverrides)("toPromise", [], overrides, cases);
    (0, _internals_1.defineOverrides)("toThenable", [], overrides, cases);
});
mark(Thenable);
exports.default = Thenable;
