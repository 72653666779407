"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.greaterThanOrEqual = exports.lessThanOrEqual = exports.greaterThan = exports.lessThan = exports.stringMatcher = exports.stringSwitch = exports.toString = exports.show = exports.foldMap = exports.hasInstance = exports.getTypeRep = exports.getTypeName = exports.getVariant = exports.getTag = exports.unwrap = exports.match = void 0;
const _internals_1 = require("../_internals");
const S = __importStar(require("../_internals/symbols"));
/**
 * Type match a value
 * @param {{ match: (cases: any) => any }} value
 * @param {any} cases
 */
const match = (value, cases) => cases === undefined ? (cases) => (0, _internals_1.safeMatch)(value, cases) : (0, _internals_1.safeMatch)(value, cases);
exports.match = match;
/**
 * Calls unwrap on the given object
 * @param {{ unwrap: () => any }} x
 */
const unwrap = (x) => x.unwrap();
exports.unwrap = unwrap;
/**
 * Returns variant name of the given value
 * @param {any} v
 */
exports.getTag = S.getVariant;
exports.getVariant = S.getVariant;
/**
 * Returns the name of the union this value belongs to
 * @param {any} v
 * @returns {string} TypeName
 */
exports.getTypeName = S.getTypeName;
/**
 * Returns the type representative of this value
 * @param {any} v
 * @returns Type representative
 */
exports.getTypeRep = S.getTypeRep;
/**
 * Returns true if value implements the provided typeclass or typeclass name
 * @param {any} value
 * @param {any} typeclass
 */
const hasInstance = (tc, x) => { var _a; return (0, _internals_1.includes)(S.getTypeclass(tc) || tc, ((_a = S.getTypeclasses(x)) === null || _a === void 0 ? void 0 : _a()) || []); };
exports.hasInstance = hasInstance;
const foldMap = (t, values) => values.reduce((acc, next) => acc.concat(t.of(next)), t.empty());
exports.foldMap = foldMap;
const show = (x) => x.show();
exports.show = show;
const toString = (x) => x.toString();
exports.toString = toString;
const stringSwitch = (str, _patt) => {
    return (0, _internals_1.extractWith)([])((0, _internals_1.getCaseSensitive)(str, _patt));
};
exports.stringSwitch = stringSwitch;
const stringMatcher = (str) => {
    return {
        match: (patterns) => {
            return (0, _internals_1.extractWith)([])((0, _internals_1.getCaseSensitive)(str, patterns));
        }
    };
};
exports.stringMatcher = stringMatcher;
const lessThan = (l, r) => {
    if ((0, exports.hasInstance)("Ord", l) && (0, exports.hasInstance)("Ord", r)) {
        return l.lessThan(r);
    }
    return l < r;
};
exports.lessThan = lessThan;
const greaterThan = (l, r) => {
    if ((0, exports.hasInstance)("Ord", l) && (0, exports.hasInstance)("Ord", r)) {
        return l.greaterThan(r);
    }
    return l > r;
};
exports.greaterThan = greaterThan;
const lessThanOrEqual = (l, r) => {
    if ((0, exports.hasInstance)("Ord", l) && (0, exports.hasInstance)("Ord", r)) {
        return l.lessThanOrEqual(r);
    }
    return l <= r;
};
exports.lessThanOrEqual = lessThanOrEqual;
const greaterThanOrEqual = (l, r) => {
    if ((0, exports.hasInstance)("Ord", l) && (0, exports.hasInstance)("Ord", r)) {
        return l.greaterThanOrEqual(r);
    }
    return l >= r;
};
exports.greaterThanOrEqual = greaterThanOrEqual;
