"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _internals_1 = require("../_internals");
const symbols_1 = require("../_internals/symbols");
const mark = (0, symbols_1.setTypeclass)("Foldable");
/**
 * Adds fold method to proto
 */
const Foldable = (defs) => mark((cases) => {
    const overrides = (0, _internals_1.prop)("overrides")(defs);
    Object.keys(cases).forEach((key) => {
        var _a;
        cases[key].prototype.fold = (_a = overrides === null || overrides === void 0 ? void 0 : overrides.fold) === null || _a === void 0 ? void 0 : _a[key];
    });
});
(0, symbols_1.setTypeclass)("Foldable")(Foldable);
exports.default = Foldable;
