"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _internals_1 = require("../_internals");
const symbols_1 = require("../_internals/symbols");
/**
 * Adds chain, bind and flatMap method to proto. Adds return and do to global. Requires Applicative
 */
const Monad = (defs) => (0, symbols_1.setTypeclass)("Monad")((cases, globals) => {
    const trivials = (0, _internals_1.propOr)([], "trivials", defs);
    const identities = (0, _internals_1.propOr)([], "identities", defs);
    const overrides = (0, _internals_1.propOr)({}, "overrides", defs);
    const lazy = (0, _internals_1.propOr)(false, "lazy", defs);
    const pureM = (0, _internals_1.propOr)((0, _internals_1.prop)("pure")(defs), "return", defs);
    trivials.forEach(trivial => {
        function chain(fn) {
            return fn(this.get());
        }
        cases[trivial].prototype.chain = chain;
        cases[trivial].prototype.flatMap = chain;
        function join() {
            return this.get();
        }
        cases[trivial].prototype.join = join;
        cases[trivial].prototype.flat = join;
    });
    identities.forEach(empt => {
        function chain(_fn) {
            return this;
        }
        cases[empt].prototype.chain = chain;
        cases[empt].prototype.flatMap = chain;
        cases[empt].prototype.flat = chain;
        cases[empt].prototype.join = chain;
    });
    (0, _internals_1.defineOverrides)("chain", ["bind", "flatMap"], overrides, cases);
    (0, _internals_1.defineOverrides)("join", ["flat"], overrides, cases);
    globals.return = (...args) => new cases[pureM](...args);
    globals.do = function (fn) {
        let gen = undefined;
        const runDo = (prev) => {
            let monad = gen.next(prev);
            if (monad.done) {
                return monad.value;
            }
            else {
                return monad.value.flatMap(runDo);
            }
        };
        if (lazy) {
            return this.return((...args) => {
                gen = fn(this.return);
                return runDo(undefined).unsafeRun(...args);
            });
        }
        gen = fn(this.return);
        return runDo(undefined);
    };
});
(0, symbols_1.setTypeclass)("Monad")(Monad);
exports.default = Monad;
