"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPrimitive = exports.makeTuple = exports.isFunction = exports.coerce = exports.isNotNil = exports.isNil = exports.extract = exports.extractWith = exports.assoc = exports.propOr = exports.prop = exports.merge = exports.compose2 = exports.pass = exports.identity = exports.isEmpty = exports.empty = exports.equals = void 0;
const equals = (a, b) => {
    const typeA = typeof a;
    const typeB = typeof b;
    if (typeA !== typeB || typeA === "function" || typeB === "function") {
        return false;
    }
    if (typeA === "object") {
        if (a === b) {
            return true;
        }
        if (typeof (a === null || a === void 0 ? void 0 : a.equals) === "function") {
            return a.equals(b);
        }
        if (typeof (b === null || b === void 0 ? void 0 : b.equals) === "function") {
            return b.equals(a);
        }
        if (Array.isArray(a) && Array.isArray(b)) {
            return a.every((_a, idx) => (0, exports.equals)(_a, b[idx]));
        }
        if ((Array.isArray(a) && !Array.isArray(b)) ||
            (!Array.isArray(a) && Array.isArray(b))) {
            return false;
        }
        const setAB = new Set([...Object.keys(a), ...Object.keys(b)]);
        return Array.from(setAB).every(key => (0, exports.equals)(a[key], b[key]));
    }
    return a === b;
};
exports.equals = equals;
const empty = (what) => {
    var _b;
    const anyWho = what;
    const typ = typeof anyWho;
    if (typeof (anyWho === null || anyWho === void 0 ? void 0 : anyWho.empty) === "function") {
        return anyWho.empty();
    }
    else if (typeof ((_b = anyWho === null || anyWho === void 0 ? void 0 : anyWho.constructor) === null || _b === void 0 ? void 0 : _b.empty) === "function") {
        return anyWho.constructor.empty();
    }
    else if (Array.isArray(what)) {
        return [];
    }
    else if (typ === "string") {
        return "";
    }
    else if (typ === "object") {
        return {};
    }
};
exports.empty = empty;
const isEmpty = (what) => (0, exports.equals)(what, (0, exports.empty)(what));
exports.isEmpty = isEmpty;
const identity = (x) => x;
exports.identity = identity;
function pass() { return this; }
exports.pass = pass;
const compose2 = (fn0, fn1) => (...args) => fn0(fn1(...args));
exports.compose2 = compose2;
const merge = (a, b) => (Object.assign(Object.assign({}, a), b));
exports.merge = merge;
const prop = (key) => (obj) => { var _b; return (_b = obj) === null || _b === void 0 ? void 0 : _b[key]; };
exports.prop = prop;
const propOr = (or, key, obj) => { var _b; return (_b = (0, exports.prop)(key)(obj)) !== null && _b !== void 0 ? _b : or; };
exports.propOr = propOr;
const assoc = (key, value, obj) => (Object.assign(Object.assign({}, obj), { [key]: value }));
exports.assoc = assoc;
const extractWith = (args) => (ext) => ext instanceof Function ? ext(...args) : ext;
exports.extractWith = extractWith;
exports.extract = (0, exports.extractWith)([]);
const isNil = (a) => a === null || a === undefined;
exports.isNil = isNil;
const isNotNil = (a) => !(0, exports.isNil)(a);
exports.isNotNil = isNotNil;
const coerce = (a) => a;
exports.coerce = coerce;
const isFunction = (x) => typeof x === "function";
exports.isFunction = isFunction;
const makeTuple = (a, b) => [a, b];
exports.makeTuple = makeTuple;
const isPrimitive = (a) => typeof a !== "object" && typeof a !== "function";
exports.isPrimitive = isPrimitive;
