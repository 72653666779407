"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.monoidToPromise = exports.monoidThen = exports.defineOverrides = exports.forEachValue = exports.splitBy = exports.safeMatch = exports.getCaseSensitive = exports.getCase = exports.expandCases = exports.includes = exports.getFirstCaseSensitive = exports.toPairs = exports.fromPairs = void 0;
const functions_1 = require("./functions");
__exportStar(require("./functions"), exports);
const fromPairs = (pairs) => pairs.reduce((acc, [key, val]) => {
    acc[key] = val;
    return acc;
}, {});
exports.fromPairs = fromPairs;
const toPairs = (obj) => Object.keys(obj).map((key) => [key, obj[key]]);
exports.toPairs = toPairs;
const mapKeys = (fn, obj) => (0, exports.fromPairs)((0, exports.toPairs)(obj).map(([key, val]) => [fn(key), val]));
const toLower = (str) => str.toLowerCase();
const getFirstCaseSensitive = (ps, obj) => {
    const mappedObj = mapKeys(toLower, obj);
    const matched = ps.find(p => !(0, functions_1.isNil)(mappedObj[p]));
    return matched ? mappedObj[matched] : undefined;
};
exports.getFirstCaseSensitive = getFirstCaseSensitive;
const getFirstCaseInsensitive = (ps, obj) => {
    const mappedObj = mapKeys(toLower, obj);
    const matched = ps.map(toLower).find(p => !(0, functions_1.isNil)(mappedObj[p]));
    return matched ? mappedObj[matched] : undefined;
};
const includes = (val, arr) => Boolean(arr.find(x => x === val));
exports.includes = includes;
const expandCases = (obj) => {
    return (0, exports.fromPairs)((0, exports.toPairs)(obj).flatMap(([key, value]) => {
        if (key.includes("|")) {
            return key
                .split("|")
                .map(x => x.trim())
                .filter(Boolean)
                .map(k => [k, value]);
        }
        return [[key, value]];
    }));
};
exports.expandCases = expandCases;
const getCase = (name, obj) => getFirstCaseInsensitive([name, "default", "_"], obj);
exports.getCase = getCase;
const getCaseSensitive = (name, obj) => (0, exports.getFirstCaseSensitive)([name, "default", "_"], obj);
exports.getCaseSensitive = getCaseSensitive;
const safeMatch = (val, cases) => { var _a, _b, _c; return ((_a = val === null || val === void 0 ? void 0 : val.match) === null || _a === void 0 ? void 0 : _a.call(val, cases)) || ((_b = cases === null || cases === void 0 ? void 0 : cases.default) === null || _b === void 0 ? void 0 : _b.call(cases, val)) || ((_c = cases === null || cases === void 0 ? void 0 : cases._) === null || _c === void 0 ? void 0 : _c.call(cases, val)); };
exports.safeMatch = safeMatch;
const splitBy = (fn, arr) => arr.reduce(([left, right], next) => {
    return (fn(next) ? [left, [...right, next]] : [[...left, next], right]);
}, [[], []]);
exports.splitBy = splitBy;
/**
 * iterates over values of an object
 * @param {(value: any, key?: string | number | symbol, index?: number, data?: any) => } fn
 * @param {any} data
 */
const forEachValue = (fn, data = {}) => {
    Object.keys(data).forEach((key, idx) => {
        fn(data[key], key, idx, data);
    });
};
exports.forEachValue = forEachValue;
/**
 * Sets overrides on the cases proto
 * @param {string} method
 * @param {string[]} aliases
 * @param {any} overrides
 * @param {any} cases
 */
const defineOverrides = (method, aliases, overrides, cases) => {
    (0, exports.forEachValue)((override, key) => {
        cases[key].prototype[method] = override;
        aliases.forEach(alias => {
            cases[key].prototype[alias] = override;
        });
    }, overrides[method] || {});
};
exports.defineOverrides = defineOverrides;
function monoidThen(res = functions_1.identity, rej = functions_1.identity) {
    (0, functions_1.isEmpty)(this) ? rej(this.get()) : res(this.get());
}
exports.monoidThen = monoidThen;
function monoidToPromise() {
    return new Promise((res, rej) => {
        (0, functions_1.isEmpty)(this) ? rej(this.get()) : res(this.get());
    });
}
exports.monoidToPromise = monoidToPromise;
